// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyC2LE3lvRiO2Jztrs0ZAqbP3WX8HLvI1lI",
  authDomain: "bezoek-registratie.firebaseapp.com",
  projectId: "bezoek-registratie",
  storageBucket: "bezoek-registratie.appspot.com",
  messagingSenderId: "205648403413",
  appId: "1:205648403413:web:d42f7e41d02b9e698abb5c",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

export const db = getDatabase(
  app,
  "https://bezoek-registratie-default-rtdb.europe-west1.firebasedatabase.app"
);
